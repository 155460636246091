import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

const GalleryPage = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Gallery', url: '/gallery', active: true }
    ];
    return (
        <>
            <Navbar />
            <HeroHeader title="Gallery" breadcrumbLinks={breadcrumbLinks} />
            <Gallery />
            <Footer />
        </>
    )
}

export default GalleryPage