import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled, { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import AOS from "aos";
import axios from "axios";

// Styled components for custom styles
const TestimonialItem = styled.div`
  background-color: transparent;
  color: #FEA116;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin: 0 15px;
  transition: background-color 0.3s ease;
  min-height: 250px;
  // max-width: 300px;
  // width: 100%; 
  width:300px
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center; 

    &:hover {
    background-color: #FEA116;
    color: #F1F8FF;
    border: none;
  }

  .testimonial-content {
    display: block;
    width: 100%;
    max-height: 140px; /* Limit the height of content */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap; /* Single line truncation */
    word-break: break-word;
  }


  .testimonial-icon {
    background-color: white;
    padding: 8px;
    border-radius: 50%;
  }

  .testimonial-footer {
    margin-top: auto; 
  }
`;

const GlobalStyle = createGlobalStyle`
  .slick-dots li button:before {
    font-size: 18px;
    color: #FEA116 !important;
    border: 2px solid transparent !important;
    margin-top: 2rem;
  }
  .slick-dots .slick-active button:before {
    font-size: 18px;
    color: #FEA116 !important;
    margin-top: 2rem;
  }
`;

const Testimonials = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [reviews, setReviews] = useState([]);
  console.log("🚀 ~ reviews:", reviews)

  useEffect(() => {
    // Initialize AOS animations
    AOS.init({
      duration: 1500, // Animation duration in milliseconds (1.5 seconds)
      offset: 400, // Start animation 200px before the section enters the viewport
      once: true, // Trigger only once
      anchorPlacement: "top-bottom", // Trigger when top of the element enters the viewport
    });

    // Refresh AOS on resize
    const handleResize = () => AOS.refresh();
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   arrows: false,
  //   centerMode: true,
  //   centerPadding: "0",
  //   beforeChange: (current, next) => setCenterIndex(next),
  //   responsive: [
  //     { breakpoint: 1024, settings: { slidesToShow: 2 } },
  //     { breakpoint: 768, settings: { slidesToShow: 1 } },
  //   ],
  // };

  const settings = {
    dots: true,
    infinite: reviews.length > 1,
    speed: 1000,
    slidesToShow: reviews?.length === 1 ? 1 : reviews?.length === 2 ? 2 : 3,
    // slidesToShow:3 ,
    slidesToScroll: 1,
    autoplay: true,  // Enable autoplay if there are more than 2 reviews
    // autoplaySpeed: 3000,
    arrows: false,
    // centerMode: true,
    // centerPadding: reviews.length === 1 ? "30%" : reviews.length === 2 ? "15%" : "0%",
    // beforeChange: (current, next) => setCenterIndex(next),
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/home`);
        const reviews = response.data?.data?.reviews || [];
        setReviews(reviews); // Set categories as dishes
      } catch (err) {
        // setError(err.message || "Error loading data.");
        setError(
          <div
            className="text-center w-100"
            style={{
              padding: '50px 20px',
              backgroundColor: '#f8f9fa',
              border: '2px dashed #dee2e6',
              borderRadius: '8px',
              marginTop: '20px',
            }}
          >
            <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
              We're gathering customer feedback!
            </h3>
            <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
              Hang tight, customer testimonials will be displayed shortly.
            </p>
          </div>)
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    const fullStars = Array(rating).fill("★"); // Array of full stars based on rating
    return fullStars.join(""); // Combine and return full + empty stars
  };

  return (
    <div
      className="container-xxl py-5"
      data-aos="fade-up" // Add AOS animation to the container
      data-aos-duration="1500" // Make the fade-up slower (1.5 seconds)
      data-aos-offset="400" // Start animation 200px before entering the viewport
      data-aos-anchor-placement="top-bottom"
    >
      <GlobalStyle />
      <div className="container" style={{ marginBottom: '3rem' }}>
        <div className="text-center">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            Testimonial
          </h5>
          <h1 className="mb-5">Our Clients Say!!!</h1>
        </div>
        {loading && <div
          className="text-center w-100"
          style={{
            padding: '50px 20px',
            backgroundColor: '#f8f9fa',
            border: '2px dashed #dee2e6',
            borderRadius: '8px',
            marginTop: '20px',
          }}
        >
          <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
            We're gathering customer feedback!
          </h3>
          <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
            Hang tight, customer testimonials will be displayed shortly.
          </p>
        </div>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && reviews.length === 0 && <div
          className="text-center w-100"
          style={{
            padding: '50px 20px',
            backgroundColor: '#f8f9fa',
            border: '2px dashed #dee2e6',
            borderRadius: '8px',
            marginTop: '20px',
          }}
        >
          <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
            We're gathering customer feedback!
          </h3>
          <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
            Hang tight, customer testimonials will be displayed shortly.
          </p>
        </div>}

        {!loading && !error && reviews.length > 0 && (
          <Slider {...settings} className="dish-carousel">
            {reviews.map((testimonial, index) => {
              return (
                <div key={index}>
                  <TestimonialItem>
                    <div className="testimonial-content">
                      <i className="fa fa-quote-left fa-2x text-primary mb-3 testimonial-icon"></i>
                      <p>{testimonial.comment}</p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="d-flex align-items-center">
                        <div>
                          <h5 className="mb-1">{testimonial?.customer?.name}</h5>
                          <small style={{ fontSize: "1.5rem" }}>
                            {renderStars(testimonial.rating)}
                          </small>
                        </div>
                      </div>
                    </div>
                  </TestimonialItem>
                </div>
              );
            })}


          </Slider>
        )}
      </div >
    </div >
  );
};

export default Testimonials;
