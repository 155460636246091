import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/scss/bootstrap.scss'
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import AboutPage from './Pages/AboutPage';
import Home from './Pages/Home';
import WOW from 'wow.js';  // Import WOW.js
import MenuPage from './Pages/MenuPage';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import ScrollToTop from './Components/ScrollToTop';
import BookingPage from './Pages/BookingPage';
import { Analytics } from '@vercel/analytics/react';
import GalleryPage from './Pages/GalleryPage';
import ReviewComponent from './Pages/Review';

const App = () => {
    useEffect(() => {
        new WOW().init(); 
    }, []);
    
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/career" element={<Career />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/review" element={<ReviewComponent />} />
            </Routes>
            <Analytics />
        </Router>
    );
};

export default App;


