/* eslint-disable no-console */
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'; // Replace require with import

export const awsDownload = async (filename, duration) => {
  const s3Client = new S3Client({
    endpoint: process.env.REACT_APP_LINODE_ENDPOINT,
    region: process.env.REACT_APP_LINODE_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    },
  });

  if (filename) {
    const command = new GetObjectCommand({
      Bucket: process.env.REACT_APP_BUCKET_ID,
      Key: filename,
      ResponseContentDisposition: 'inline', // Prevent automatic downloads
      ResponseContentType: 'text/plain', // Adjust content type
    });

    const url = await getSignedUrl(s3Client, command, {
      expiresIn: duration || 36000, // Default duration: 36000 seconds
    });

    return url;
  } else {
    return null;
  }
};
