import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import backgroundImage from "../assets/food-background.jpg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(
      rgba(0, 0, 0, 0.8), 
      rgba(0, 0, 0, 0.8)
    ), 
    url(${backgroundImage}) center/cover no-repeat;
  padding: 20px;
  animation: fadeIn 1.5s ease-out;
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Card = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  text-align: center;
  animation: slideUp 1s ease-out;

  @keyframes slideUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Title = styled.h2`
  font-size: 28px;
  color: #FEA116;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #0F172B;
  margin-bottom: 20px;
`;

const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const Star = styled.span`
  font-size: 40px;
  cursor: pointer;
  color: ${(props) => (props.highlighted ? "#FFD700" : "#E0E0E0")};
  transition: transform 0.3s ease, color 0.2s ease;

  &:hover {
    transform: scale(1.3);
    color: #ffc107;
  }
`;

const Emoji = styled.div`
  font-size: 50px;
  margin-bottom: 10px;
  transition: transform 0.2s ease;

  &.fade-in {
    animation: fadeInEmoji 0.5s ease-out;
  }

  &.celebrate {
    animation: explode 1s ease-out infinite;
    position: relative;
  }

  @keyframes fadeInEmoji {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes explode {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.4);
      opacity: 0.6;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  animation: fadeInUp 1s ease-out;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid ${(props) => (props.isValid ? "#ddd" : "#e74c3c")};
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${(props) => (props.isValid ? "#3498db" : "#e74c3c")};
  }
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  color: #e74c3c;
  text-align: left;
`;

const Button = styled.button`
  background: linear-gradient(45deg, #ff6a00, #ee0979);
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(255, 105, 135, 0.3);
  transition: background 0.3s, transform 0.2s, box-shadow 0.3s;

  &:hover {
    background: linear-gradient(45deg, #ee0979, #ff6a00);
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(255, 105, 135, 0.5);
  }
`;

const ReviewComponent = () => {
  const [selectedStars, setSelectedStars] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(0);  // State for hovering
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);  // To show fireworks on 5-star rating

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  const [formValidity, setFormValidity] = useState({
    name: true,
    email: true,
    phone: true,
    comment: true,
  });

  const emojis = [
    "😡",  // 1 star
    "😕",  // 2 stars
    "😐",  // 3 stars
    "😊",  // 4 stars
    "😍"   // 5 stars
  ];

  const handleStarClick = (rating) => {
    setSelectedStars(rating);
    if (rating === 5) {
      setShowFireworks(true);
    } else {
      setShowFireworks(false);
    }
  };

  const handleStarHover = (rating) => {
    setHoveredStar(rating);
  };

  const handleStarLeave = () => {
    setHoveredStar(0);
  };

  const validateForm = () => {
    const isValid = {
      name: formData.name.trim() !== "",
      email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email),
      phone: /^\d{10}$/.test(formData.phone),
      comment: formData.comment.trim() !== "",
    };

    setFormValidity(isValid);
    return Object.values(isValid).every(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const reviewData = {
      ...formData,
      rating: selectedStars,
    };

    try {
      setIsSubmit(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/review`, reviewData);
      setResponseMessage(response.data.message || 'Your message has been sent successfully!');

      if (selectedStars > 3) {
        const reviewLink = process.env.REACT_APP_GOOGLE_REVIEW_LINK;
        if (reviewLink) {
          window.open(reviewLink, "_blank"); // Open the link in a new tab
        } else {
          console.error("Google Review link is not defined");
        }
      }

      setFormData({
        name: "",
        email: "",
        phone: "",
        comment: "",
      });
      setSelectedStars(0);
    } catch (error) {
      console.error("Error submitting review:", error);
    }
    finally {
      setIsSubmit(false);
    }
  };

  return (
    <Container>
      <Card>
        <Title>We Value Your Feedback</Title>
        <Subtitle>Rate your experience with our service</Subtitle>

        {/* Display emoji based on hovered or selected stars */}
        <Emoji className={`${selectedStars === 5 ? 'celebrate' : ''} ${selectedStars || hoveredStar ? "fade-in" : ""}`}>
          {emojis[hoveredStar > 0 ? hoveredStar - 1 : selectedStars - 1] || "🙂"}
        </Emoji>

        <StarsContainer>
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              onClick={() => handleStarClick(star)}
              onMouseEnter={() => handleStarHover(star)}
              onMouseLeave={handleStarLeave}
              highlighted={star <= (hoveredStar || selectedStars)} // Highlight all stars up to the hovered or selected one
            >
              ★
            </Star>
          ))}
        </StarsContainer>

        {selectedStars > 0 && (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isValid={formValidity.name}
            />
            {!formValidity.name && <ErrorMessage>Please enter your name.</ErrorMessage>}

            <Input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              isValid={formValidity.email}
            />
            {!formValidity.email && <ErrorMessage>Please enter a valid email address.</ErrorMessage>}

            <Input
              type="text"
              name="phone"
              placeholder="Your Phone"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              isValid={formValidity.phone}
              maxLength={10}
            />
            {!formValidity.phone && <ErrorMessage>Please enter a valid phone number.</ErrorMessage>}

            <Input
              type="text"
              name="comment"
              placeholder="Your Feedback"
              value={formData.comment}
              onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
              isValid={formValidity.comment}
            />
            {!formValidity.comment && <ErrorMessage>Please enter your feedback.</ErrorMessage>}

            {/* <Button type="submit">Submit Review</Button> */}
            <Button variant="primary" type="submit" className="w-100 py-3">
              {isSubmit ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        )}
      </Card>
    </Container>
  );
};

export default ReviewComponent;
