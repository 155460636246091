import React, { useEffect, useState } from "react";
import logo from '../assets/logo.png';
import { FaBars } from "react-icons/fa";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../assets/css/style.css'
import { NavLink } from "react-router-dom";


const Navbar = () => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const [isNavbarSticky, setIsNavbarSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust threshold as needed
                setIsNavbarSticky(true);
            } else {
                setIsNavbarSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };

    return (
        <nav
            className={`navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0 nav-link ${isNavbarSticky ? "sticky-top" : ""
                }`}
        >
            <a href="/" className="navbar-brand p-0">
                <img src={logo} alt="Logo" style={{ width: "100px" }} />
            </a>
            <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}  // Manually toggle navbar collapse
                aria-controls="navbarCollapse"
                aria-expanded={isNavbarCollapsed ? "false" : "true"}  // Control aria-expanded dynamically
                aria-label="Toggle navigation"
            >
                <FaBars />
            </button>
            <div
                className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
                id="navbarCollapse"
            >
                <div className="navbar-nav ms-auto py-0 pe-4">
                    <NavLink to="/" className="nav-item nav-link" activeClassName="active">
                        Home
                    </NavLink>
                    <NavLink to="/about" className="nav-item nav-link" activeClassName="active">
                        About
                    </NavLink>
                    <NavLink to="/menu" className="nav-item nav-link" activeClassName="active">
                        Menu
                    </NavLink>
                    <NavLink to="/gallery" className="nav-item nav-link" activeClassName="active">
                        Gallery
                    </NavLink>
                    <NavLink to="/contact" className="nav-item nav-link" activeClassName="active">
                        Contact
                    </NavLink>
                    <NavLink to="/career" className="nav-item nav-link" activeClassName="active">
                        Career
                    </NavLink>
                </div>
                {/* <button className="btn btn-primary py-2 px-4">Book A Table</button> */}
                <NavLink to="/booking" className="nav-item" activeClassName="active" >
                <button className="btn btn-primary py-2 px-4">Book A Table</button>
                    </NavLink>
            </div>
        </nav>
    );
};

export default Navbar;
