import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/food-background.jpg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background: linear-gradient(
      rgba(0, 0, 0, 0.8), 
      rgba(0, 0, 0, 0.8)
    ), 
    url(${backgroundImage}) center/cover no-repeat;
  padding: 20px;
  animation: fadeIn 1.5s ease-out;
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Card = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  text-align: center;
  animation: slideUp 1s ease-out;

  @keyframes slideUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Title = styled.h2`
  font-size: 28px;
  color: #FEA116;
  margin-bottom: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
   font-size: 21px;
  }
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #0F172B;
  margin-bottom: 20px;
  @media (max-width: 768px) {
   font-size: 15px;
  }
`;

const ReviewComponent = () => {

  return (
    <Container>
      <Card>
        <Title>Rate Your Experience</Title>
        <Subtitle>Your opinion helps us grow. Please let us know how we’re doing!</Subtitle>
        <a className="btn btn-primary py-3 px-5 mt-2" href="/review">
          Rate Us
        </a>
      </Card>
    </Container>
  );
};

export default ReviewComponent;
