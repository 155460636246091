import React, { useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import WOW from "wowjs";


const ServiceItem = ({ iconClass, title, description, delay, animation }) => {

    useEffect(() => {
    new WOW.WOW().init();
}, []);

    return (
        <div className={`col-lg-3 col-sm-6 wow ${animation}`} data-wow-delay={delay}>
            <div className="service-item rounded pt-3 h-100">
                <div className="p-4">
                    <i className={`fa fa-3x ${iconClass} text-primary mb-4`}></i>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};


const Service = () => {
    const services = [
        {
            iconClass: "fa-user-tie",
            title: "Master Chefs",
            description: "Our expert chefs bring years of experience and passion to the kitchen, crafting dishes that are both flavorful and visually stunning.",
            delay: "0.1s",
            animation: "fadeInUp", 
        },
        {
            iconClass: "fa-utensils",
            title: "Quality Food",
            description: "We use the freshest ingredients and authentic recipes to ensure every bite is delicious, satisfying, and memorable.",
            delay: "0.3s",
            animation: "fadeInUp", 
        },
        {
            iconClass: "fa-light fa-house",
            title: "Cozy Ambience",
            description: "Enjoy your meal in a warm, inviting atmosphere perfect for family dinners, romantic dates, or casual gatherings.",
            delay: "0.5s",
            animation: "fadeInUp", 
        },
        {
            iconClass: "fa-headset",
            title: "Fast Service",
            description: "We pride ourselves on quick and efficient service, ensuring you enjoy your food fresh and without the wait.",
            delay: "0.7s",
            animation: "fadeInUp", 
        },
    ];

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-4">
                    {services.map((service, index) => (
                        <ServiceItem
                            key={index}
                            iconClass={service.iconClass}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            animation={service.animation}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Service;
