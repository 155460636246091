import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader';
import Footer from '../Components/Footer';
import Contact from '../Components/ContactInfo';

const Career = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Contact', url: '/contact', active: true }
    ];
  return (
   <>
   <Navbar/>
   <HeroHeader title="Contact Us" breadcrumbLinks={breadcrumbLinks} />
   <Contact/>
   <Footer/>
   </>
  )
}

export default Career