import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import FallbackSpinner from "../Components/spinner/Spinner"

const Contact = () => {

    const googleMapsSrc = process.env.REACT_APP_GOOGLE_MAPS_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [settings, setSettings] = useState(null);
    const defaultSettings = {
        address: "18 Watford Rd, Wembley HA0 3EP, UK",
        mobile: "02080544695",
        email: "contact@foodiechoice.co.uk",
    };

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/settings`);
                const data = response?.data?.data?.settings;
                setSettings(data || defaultSettings);
            } catch (err) {
                console.log("🚀 ~ fetchSetting ~ err:", err)
            }
        };

        fetchSetting();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity()) {
            setIsSubmitting(true);
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${apiUrl}/contact`, formData);
                setResponseMessage(response.data.message || 'Your message has been sent successfully!');
                setFormData({ name: '', email: '', subject: '', message: '' });
                setValidated(false);
            } catch (error) {
                console.error('Error sending message:', error);
                setResponseMessage('An error occurred while sending your message. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    return (
        <div className="container-xxl py-5">
            <div className="container mb-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">Contact Us</h5>
                    <h1 className="mb-5">Contact For Any Query</h1>
                </div>

                {!settings ? (
                    <FallbackSpinner />
                ) : (
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary" >Phone Number</h5>
                                    <p>
                                        <a href="tel:02080544695" className="text-decoration-none">
                                            <i className="fa fa-phone me-2" style={{ color: "inherit" }}></i>
                                            <span style={{ color: "#666565" }}>{settings.mobile}</span>
                                        </a>
                                    </p>

                                </div>
                                <div className="col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary">Email</h5>
                                    <p>
                                        <a href="mailto:contact@foodiechoice.co.uk" className="text-decoration-none">
                                            <i className="fa fa-envelope me-2" style={{ color: "inherit" }}></i>
                                            <span style={{ color: "#666565" }}>{settings.email}</span>
                                        </a>
                                    </p>
                                </div>
                                <div className="col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary " >Address</h5>
                                    <p>
                                        <a
                                            href="https://maps.app.goo.gl/5dXJahrYHzcMR4wKA"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-decoration-none"
                                            style={{ color: "inherit" }}
                                        >
                                            <i className="fa fa-location text-primary me-2"></i>
                                            {settings.address}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe
                                className="position-relative rounded w-100 h-100"
                                src={googleMapsSrc}
                                frameBorder="0"
                                style={{ minHeight: "350px", border: 0 }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                                title="Foodie's Choice - Google Map"
                            ></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <Form.Group controlId="name">
                                                <Form.Label>Your Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your Name"
                                                    required
                                                    name='name'
                                                    value={formData.name}
                                                    // onChange={handleInputChange}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // Allow only alphabets
                                                        if (/^[a-zA-Z\s]*$/.test(value)) {
                                                            handleInputChange(e); // Call your existing input change handler
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide your name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="email">
                                                <Form.Label>Your Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Your Email"
                                                    required
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-12">
                                            <Form.Group controlId="subject">
                                                <Form.Label>Subject</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Subject"
                                                    required
                                                    name='subject'
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a subject.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-12">
                                            <Form.Group controlId="message">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a message here"
                                                    style={{ height: "150px" }}
                                                    required
                                                    name='message'
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a message.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-12">
                                            <Button className="btn btn-primary w-100 py-3" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Sending...' : 'Send Message'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                {responseMessage && (
                                    <div className="mt-3 alert alert-info" role="alert">
                                        {responseMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;
