import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Modal, Card } from 'react-bootstrap';
import about1 from '../assets/img/image-4.png';
import about2 from '../assets/img/image-4.png';
import about3 from '../assets/img/image-3.png';
import about4 from '../assets/img/image.png';
import axios from 'axios';
import { awsUpload } from '../Pages/api/linode-setup/upload';

const CareerInfo = () => {

    const [jobOpenings, setJobOpenings] = useState([]);

    const [formData, setFormData] = useState({
        applicant_name: '',
        applicant_email: '',
        applicant_position: '',
        career_u_id: '',
        resume_url: '',
        applicant_phone: '',
        cover_letter: '',
    });

    const [errors, setErrors] = useState({
        applicant_name: '',
        applicant_email: '',
        applicant_position: '',
        career_u_id: '',
        resume_url: '',
        applicant_phone: '',
        cover_letter: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    // Fetch job openings from API

    useEffect(() => {

        const fetchJobOpenings = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/careers`);
                // Check if the response contains a valid data field (array)
                if (Array.isArray(response.data.data.careers)) {
                    setJobOpenings(response.data.data.careers); // Set job openings array from response.data.data
                } else {
                    console.error('Expected an array but got:', response.data.data);
                    setJobOpenings([]);
                }
            } catch (error) {
                console.error('Error fetching job openings:', error);
                setJobOpenings([]); // Fallback to empty array on error
            }
        };

        fetchJobOpenings();

    }, []);


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'resume_url') {
            setFormData({ ...formData, resume_url: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Set loading state to true when the form is being submitted
        setIsLoading(true);

        let formValid = true;
        let newErrors = {};

        // Name validation
        if (!formData.applicant_name) {
            newErrors.applicant_name = 'Name is required';
            formValid = false;
        }

        // Email validation
        if (!formData.applicant_email) {
            newErrors.applicant_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.applicant_email)) {
            newErrors.applicant_email = 'Email address is invalid';
            formValid = false;
        }

        // Position validation
        if (!formData.applicant_position) {
            newErrors.applicant_position = 'Please select a position';
            formValid = false;
        }

        // Validate phone number
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.applicant_phone) {
            newErrors.applicant_phone = 'Phone number is required';
            formValid = false;
        } else if (!phoneRegex.test(formData.applicant_phone)) {
            newErrors.applicant_phone = 'Phone number must be exactly 10 digits';
            formValid = false;
        }

        // Cover letter validation (optional but could be required)
        if (!formData.cover_letter) {
            newErrors.cover_letter = 'Cover letter is required';
            formValid = false;
        }

        // Resume validation
        if (!formData.resume_url) {
            newErrors.resume_url = 'Resume is required';
            formValid = false;
        } else {
            // Check if the file is a PDF
            const fileExtension = formData.resume_url.name.split('.').pop().toLowerCase();
            if (fileExtension !== 'pdf') {
                newErrors.resume_url = 'Only PDF files are allowed';
                formValid = false;
            }
        }

        setErrors(newErrors);

        if (formValid) {
            try {
                // Generate a random number for the resume path and include the applicant's name
                const randomNumber = Math.floor(Math.random() * 10000000000);
                const awsResumePath = `fc-admin/resumes/${formData.applicant_name}_${randomNumber}`;

                // Upload the resume to AWS using the generated path
                const uploadResponse = await awsUpload(awsResumePath, formData.resume_url);

                if (uploadResponse?.status) {
                    const apiUrl = process.env.REACT_APP_API_URL;
                    const applicationResponse = await axios.post(`${apiUrl}/applications`, {
                        ...formData,
                        resume_url: uploadResponse.filePath, // Use the filePath from S3 as the resume URL
                    });

                    setShowModal(false);
                } else {
                    console.error('Resume upload failed');
                }
            } catch (error) {
                console.error('Error during the application process:', error);
            } finally {
                // Reset loading state after the process is completed
                setIsLoading(false);
            }
        } else {
            // Reset loading state if form validation fails
            setIsLoading(false);
        }
    };


    const handleApplyNow = (job) => {

        setFormData((prevFormData) => ({
            ...prevFormData,
            applicant_name: '',
            applicant_email: '',
            applicant_phone: '',
            cover_letter: '',
            resume_url: null,
            applicant_position: job.title,
            career_u_id: job.u_id,
        }));

        setSelectedPosition(job.title);
        setShowModal(true);
    };


    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="row g-3 mb-4">
                            <div className="col-6 text-start">
                                <img
                                    className="img-fluid rounded w-100 wow zoomIn"
                                    data-wow-delay="0.1s"
                                    src={about1}
                                    alt="About 1"
                                />
                            </div>
                            <div className="col-6 text-start">
                                <img
                                    className="img-fluid rounded w-75 wow zoomIn"
                                    data-wow-delay="0.3s"
                                    src={about2}
                                    alt="About 2"
                                    style={{ marginTop: '25%' }}
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img
                                    className="img-fluid rounded w-75 wow zoomIn"
                                    data-wow-delay="0.5s"
                                    src={about3}
                                    alt="About 3"
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img
                                    className="img-fluid rounded w-100 wow zoomIn"
                                    data-wow-delay="0.7s"
                                    src={about4}
                                    alt="About 4"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal">Careers</h5>
                        <h1 className="mb-4">
                            Join Our Restaurant Team
                        </h1>
                        <p className="mb-4" style={{textAlign:"justify"}}>
                            At <span> Foodie's Choice</span>, we believe that great food starts with great people. Our success is built on the passion, dedication, and talent of our team members, and we are always looking for new faces to join us.
                        </p>
                        <p className="mb-4" style={{textAlign:"justify"}}>
                            Whether you’re just starting your career in hospitality or you're an experienced professional seeking a new challenge, we offer a dynamic and rewarding work environment.
                        </p>
                        <a className="btn btn-primary py-3 px-5 mt-2" href="/contact">
                            Contact Us
                        </a>
                    </div>
                </div>
                <div className="row mt-4 wow fadeInUp" data-wow-delay="0.1s">
                    {jobOpenings.length > 0 ? (
                        jobOpenings.map((job) => (
                            <Col key={job.u_id} md={4}>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <Card.Title>{job.title}</Card.Title>
                                        <Card.Text>{job.description}</Card.Text>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleApplyNow(job)}
                                        >
                                            Apply Now
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <div
                            className="text-center w-100"
                            style={{
                                padding: '50px 20px',
                                backgroundColor: '#f8f9fa',
                                border: '2px dashed #dee2e6',
                                borderRadius: '8px',
                                marginTop: '20px',
                            }}
                        >
                            <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
                                No openings at the moment, but stay tuned for upcoming opportunities.
                            </h3>
                            <p style={{ fontSize: '16px', color: '#6c757d', marginTop:'1rem' }}>
                                Please check back later for exciting opportunities.
                            </p>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal for applying */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply for {selectedPosition}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="g-3">
                            <Col md={6}>
                                <Form.Group controlId="name" className="form-floating">
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Name"
                                        name="applicant_name"
                                        value={formData.applicant_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.applicant_name}
                                    />
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.applicant_name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="email" className="form-floating">
                                    <Form.Control
                                        type="email"
                                        placeholder="Your Email"
                                        name="applicant_email"
                                        value={formData.applicant_email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.applicant_email}
                                    />
                                    <Form.Label>Your Email</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.applicant_email}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="position" className="form-floating">
                                    <Form.Control
                                        as="select"
                                        name="applicant_position"
                                        value={selectedPosition}
                                        onChange={handleChange}
                                        isInvalid={!!errors.applicant_position}
                                        readOnly
                                    >
                                        <option value={selectedPosition}>{selectedPosition}</option>
                                    </Form.Control>
                                    <Form.Label>Position</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.applicant_position}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="phone" className="form-floating">
                                    <Form.Control
                                        type="tel"
                                        placeholder="Your Phone Number"
                                        name="applicant_phone"
                                        value={formData.applicant_phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.applicant_phone}
                                        maxLength={15}
                                    />
                                    <Form.Label>Your Phone Number</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.applicant_phone}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="cover_letter" className="form-floating">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Cover Letter"
                                        name="cover_letter"
                                        value={formData.cover_letter}
                                        onChange={handleChange}
                                        isInvalid={!!errors.cover_letter}
                                    />
                                    <Form.Label>Cover Letter</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.cover_letter}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="resume" className="form-floating">
                                    <Form.Control
                                        type="file"
                                        name="resume_url"
                                        onChange={handleChange}
                                        isInvalid={!!errors.resume_url}
                                    />
                                    <Form.Label>Upload Resume</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.resume_url}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Button className="btn btn-primary w-100 py-3" type="submit">
                                    {isLoading ? 'Submitting...' : 'Submit Application'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CareerInfo;
