import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader'
import Footer from '../Components/Footer';
import ReviewComponent from '../Components/ReviewComponent';

const MenuPage = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Rate Us', url: '/review', active: true }
    ];
    return (
        <>
            <Navbar />
            <HeroHeader title="Rate us" breadcrumbLinks={breadcrumbLinks} />
            <div className="container py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="text-center">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">
                        Rate Us
                    </h5>
                    <h1 className="mb-3">Tell Us What You Think</h1>
                    <p style={{fontSize:"16px"}}>Your feedback helps us improve and provide you with the best possible service. Share your thoughts and let us know how we did. Whether it’s a compliment, suggestion, or concern, we’re all ears!</p>
                </div>
            </div>
            <ReviewComponent />
            <Footer />
        </>
    )
}

export default MenuPage