import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {

  const instagram_link = process.env.REACT_APP_GOOGLE_INSTAGRAM_URL
  const facebook_link = process.env.REACT_APP_GOOGLE_FACEBOOK_URL

  const defaultSettings = {
    address: "18 Watford Rd, Wembley HA0 3EP, UK",
    mobile: "02080544695",
    email: "contact@foodiechoice.co.uk",
    instagram_link: instagram_link,
    facebook_link: facebook_link,
  };

  const [settings, setSettings] = useState(defaultSettings);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/settings`);
        const data = response?.data?.data?.settings;
        setSettings(data || defaultSettings);
      } catch (err) {
        console.log("🚀 ~ fetchSetting ~ err:", err)
        //   // setSettings(defaultSettings);
        // } finally {
        //   setLoading(false);
      }
    };

    fetchSetting();
  }, []);

  // if (!settings) {
  //   return <FallbackSpinner />;
  // }

  return (
    <div className="container-fluid bg-dark text-light footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-4">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Company</h4>
            <nav>
              <NavLink className="btn btn-link" to="/about">About Us</NavLink>
              <NavLink className="btn btn-link" to="/contact">Contact Us</NavLink>
              <NavLink className="btn btn-link" to="/booking">Reservation</NavLink>
              <NavLink className="btn btn-link" to="/career">Career</NavLink>
              <NavLink className="btn btn-link" to="/review">Rate Us</NavLink>
            </nav>
          </div>
          <div className="col-lg-4 col-md-4">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Contact</h4>
            <p className="mb-2">
              <a
                href="https://maps.app.goo.gl/5dXJahrYHzcMR4wKA"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <i className="fa fa-map-marker-alt me-3" style={{ color: "#F1F8FF" }}></i>
                <span style={{ color: "#F1F8FF" }}>{settings.address}</span>
              </a>
            </p>
            <p className="mb-2">
              <a href="tel:02080544695" className="text-decoration-none">
                <i className="fa fa-phone-alt me-3 fa-flip-horizontal" style={{ color: "#F1F8FF" }}></i>
                <span style={{ color: "#F1F8FF" }}>{settings.mobile}</span>
              </a>
            </p>
            <p className="mb-2">
              <a href="mailto:contact@foodiechoice.co.uk" className="text-decoration-none">
                <i className="fa fa-envelope me-3" style={{ color: "#F1F8FF" }}></i>
                <span style={{ color: "#F1F8FF" }}>{settings.email}</span>
              </a>
            </p>
            <div className="d-flex pt-2">
              <NavLink className="btn btn-outline-light btn-social" to={settings.instagram_link} target="_blank">
                <i className="fab fa-instagram"></i>
              </NavLink>
              <NavLink className="btn btn-outline-light btn-social" to={settings.facebook_link} target="_blank">
                <i className="fab fa-facebook-f">
                </i>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Opening</h4>
            <h5 className="text-light fw-normal">Tuesday - Sunday</h5>
            <p>12PM - 10PM</p>
            <h5 className="text-light fw-normal">Monday</h5>
            <p>Closed</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <NavLink className="border-bottom" to="/">Foodie's Choice</NavLink>, All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
            Made with ❤️ by  <a className="border-bottom" href="https://www.smoothsyncinnovations.com/" target="_blank" rel="noopener noreferrer">SmoothSync Innovation</a>
          </div>
          </div>
          
        </div>
      </div>
      {/* {loading && <FallbackSpinner />} */}
    </div>
  );
};

export default Footer;
