import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const location = useLocation(); // Get the current route

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [location]); // Whenever the location (route) changes, scroll to top

    return null; // This component doesn't render anything
};

export default ScrollToTop;
