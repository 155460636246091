import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled, { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu_img from '../assets/img/menu.png';
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import axios from "axios";

const DishItem = styled.div`
  background-color: transparent;
  color: #FEA116;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin: 0 auto;
  transition: background-color 0.3s ease;
  height: 390px; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  box-sizing: border-box;
  width: 100%;
  max-width: 300px; 

   @media (min-width: 1024px) {
    width: 300px;  /* Fixed width for larger screens */
  }

   &:hover {
    background-color: #FEA116;
    color: #F1F8FF;
    border: none;
  }

  .dish-content {
    height: 100%; /* Full height of the card */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dish-icon {
    background-color: white;
    padding: 8px;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Ensure images maintain their aspect ratio */
    border-radius: 8px;
  }

  h4 {
    margin-top: 15px;
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
    // color: black;
    // overflow: hidden;
    // text-overflow: ellipsis; /* Add ellipsis when content overflows */
    // display: -webkit-box;
    // -webkit-line-clamp: 2; /* Limit description to 3 lines */
    // -webkit-box-orient: vertical; /* Set box to vertical direction */
    // // margin-bottom: 10px;
    width: 100%;
    max-height: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap; 
    word-break: break-word;
  }
`;

const GlobalStyle = createGlobalStyle`
  .slick-dots li button:before {
    font-size: 18px;
    color: #FEA116 !important;
    border: 2px solid transparent !important;
    margin-top: 2rem;
  }
  .slick-dots .slick-active button:before {
    font-size: 18px;
    color: #FEA116 !important;
    margin-top: 2rem;
  }
`;

const FeaturedDishes = () => {
    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        // Initialize AOS animations
        AOS.init({
            duration: 1500, // Animation duration in milliseconds (1.5 seconds)
            offset: 300, // Start animation 200px before the section enters the viewport
            once: true, // Trigger only once
            anchorPlacement: "top-bottom", // Trigger when top of the element enters the viewport
        });

        // Refresh AOS on resize
        const handleResize = () => AOS.refresh();
        window.addEventListener("resize", handleResize);

        // Cleanup on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchDishes = async () => {
            try {
                setLoading(true);
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/home`);
                const categories = response.data?.data?.categories || [];
                setDishes(categories); // Set categories as dishes
            } catch (err) {
                // setError(err.message || "Error loading data.");
                setError(
                    <div
                        className="text-center w-100"
                        style={{
                            padding: '50px 20px',
                            backgroundColor: '#f8f9fa',
                            border: '2px dashed #dee2e6',
                            borderRadius: '8px',
                            marginTop: '20px',
                        }}
                    >
                        <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
                            We're getting the dishes ready!
                        </h3>
                        <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
                            Hang tight, delicious dishes will be served shortly.
                        </p>
                    </div>)
            } finally {
                setLoading(false);
            }
        };

        fetchDishes();
    }, []);

    // const settings = {
    //     infinite: true,
    //     speed: 1000,
    //     slidesToShow: dishes?.length === 1 ? 1 : dishes?.length === 2 ? 2 : 3,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     arrows: false,
    //     centerMode: true,
    //     centerPadding: "0",
    //     beforeChange: (current, next) => setCenterIndex(next),
    //     responsive: [
    //         { breakpoint: 1024, settings: { slidesToShow: 2 } },
    //         { breakpoint: 768, settings: { slidesToShow: 2 } },
    //         { breakpoint: 425, settings: { slidesToShow: 1 } },
    //         // { breakpoint: 1024, settings: { slidesToShow: dishes.length < 2 ? dishes.length : 2 } },
    //         // { breakpoint: 768, settings: { slidesToShow: dishes.length < 2 ? dishes.length : 2 } },
    //         // { breakpoint: 425, settings: { slidesToShow: dishes.length < 1 ? dishes.length : 1 } },
    //     ],
    // };

    const settings = {
        infinite: dishes.length > 1,
        speed: 1000,
        slidesToShow: dishes?.length === 1 ? 1 : dishes?.length === 2 ? 2 : 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
        ],
    };


    return (
        <div
            className="container-xxl py-5"
            data-aos="fade-up" // Add AOS animation to the container
            data-aos-duration="1500" // Make the fade-up slower (1.5 seconds)
            data-aos-offset="200" // Start animation 200px before entering the viewport
            data-aos-anchor-placement="top-bottom"
        >
            <GlobalStyle />
            <div className="container">
                <div className="text-center">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">
                        Featured Dishes
                    </h5>
                    <h1 className="mb-5">Discover Our Specialties!</h1>
                </div>

                {loading && (
                    <div
                        className="text-center w-100"
                        style={{
                            padding: '50px 20px',
                            backgroundColor: '#f8f9fa',
                            border: '2px dashed #dee2e6',
                            borderRadius: '8px',
                            marginTop: '20px',
                        }}
                    >
                        <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
                            We're getting the dishes ready!
                        </h3>
                        <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
                            Hang tight, delicious dishes will be served shortly.
                        </p>
                    </div>
                )}
                {error && <p>Error: {error}</p>}
                {!loading && !error && dishes.length === 0 && <div
                    className="text-center w-100"
                    style={{
                        padding: '50px 20px',
                        backgroundColor: '#f8f9fa',
                        border: '2px dashed #dee2e6',
                        borderRadius: '8px',
                        marginTop: '20px',
                    }}
                >
                    <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
                        We're getting the dishes ready!
                    </h3>
                    <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
                        Hang tight, delicious dishes will be served shortly.
                    </p>
                </div>}

                {!loading && !error && dishes.length > 0 && (
                    <Slider {...settings} >
                        {dishes.map((dish, index) => (
                            <div key={index}>
                                <DishItem>
                                    <div className="dish-content">
                                        <img
                                            className="img-fluid"
                                            src={dish.image_url || Menu_img}
                                            alt={dish.name}
                                            style={{ width: "100%", borderRadius: "8px" }}
                                        />
                                        <h4 className="mt-3">{dish.name}</h4>
                                        <p>{dish.description}</p>
                                        <h4>£ {dish.price}</h4>
                                    </div>
                                </DishItem>
                            </div>
                        ))}
                    </Slider>
                )}

                {/* Button to link to Menu Page */}
                <div className="text-center">
                    <Link to="/menu" className="btn btn-primary" style={{ marginTop: "3rem" }}>
                        See More Dishes
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FeaturedDishes;