import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader'
import AboutComponent from '../Components/About'
import '../assets/animate/animate.css'
import '../assets/animate/animate.min.css'
// import Team from '../Components/Team'

const AboutPage = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'About', url: '/about', active: true }
    ];
  return (
   <>
   <Navbar/>
   <HeroHeader title="About Us" breadcrumbLinks={breadcrumbLinks} />
   <AboutComponent/>
   <Footer/>
   </>
  )
}

export default AboutPage