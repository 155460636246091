import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import img from "../assets/BookTable-Img.jpg";
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2';

const generateTimeSlots = () => {
    const times = [];
    const start = moment("12:00 PM", "hh:mm A");
    const end = moment("10:00 PM", "hh:mm A");

    while (start <= end) {
        times.push(start.format("hh:mm A"));
        start.add(30, "minutes");
    }

    return times;
};

const BookTable = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        reservation_date: "",
        number_of_people: "",
        special_requests: "",
    });

    const [touchedFields, setTouchedFields] = useState({});
    const [isBooking, setIsBooking] = useState(false);
    const timeSlots = generateTimeSlots();

    // Handle form input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle field blur to mark it as touched
    const handleBlur = (e) => {
        const { name } = e.target;
        setTouchedFields({ ...touchedFields, [name]: true });
    };

    const isMonday = () => {
        if (!formData.reservation_date) return false;
        const selectedDate = moment(formData.reservation_date);
        return selectedDate.day() === 1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Mark all fields as touched to trigger validation feedback
        setTouchedFields({
            name: true,
            email: true,
            phone: true,
            reservation_date: true,
            time: true,
            number_of_people: true,
            special_requests: true,
        });

        // Check if all required fields are filled
        const requiredFields = ['name', 'email', 'phone', 'reservation_date', 'time', 'number_of_people'];
        const allFieldsFilled = requiredFields.every(field => formData[field].trim() !== '');

        // Check if the selected date is a Monday (no reservations allowed)
        if (isMonday()) {
            alert("Reservations are not allowed on Mondays.");
            return;
        }

        // If all required fields are filled, proceed with the submission
        if (allFieldsFilled) {
            try {
                setIsBooking(true);

                // Combine reservation date and time into a single datetime string
                const combinedDateTime = moment(
                    `${formData.reservation_date} ${formData.time}`,
                    "YYYY-MM-DD hh:mm A"
                ).format(); // This will format it as an ISO 8601 string

                const payload = {
                    ...formData,
                    reservation_date: combinedDateTime, // Overwrite with the formatted date-time
                };
                delete payload.time; // Remove the separate time field from the payload

                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${apiUrl}/reservation`, payload);

                Swal.fire({
                    title: "🍽️ Table Reserved!",
                    text: "Your food adventure is officially booked. Get ready to indulge!",
                    icon: "success",
                    confirmButtonText: "Yay! Can't Wait",
                    confirmButtonColor: "#ff9800",
                });
            } catch (error) {
                console.error("Error submitting reservation:", error);
                // alert("Something went wrong. Please try again.");
                Swal.fire({
                    title: "🍔 Uh-oh!",
                    text: "Looks like there’s a hiccup. Please refresh and try again!",
                    icon: "error",
                    confirmButtonText: "Okay, Got It!",
                    confirmButtonColor: "#d33",
                });
            } finally {
                setIsBooking(false); // Reset loading state
            }
        }
    };


    return (
        <Container fluid>
            <Container>
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop: "6.5rem" }}>
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">Book a Table</h5>
                    <h1 className="mb-3">Reserve Your Table</h1>
                    <p className="mb-4">Fill in the details below to reserve your table at our restaurant.</p>
                </div>
                <Row className="align-items-stretch d-flex" style={{ marginBottom: "6rem" }}>
                    <Col md={6} className="d-flex align-items-center" style={{ overflow: 'hidden', position: 'relative' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '400px', // Ensures a constant height
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={img}
                                alt="Restaurant"
                                className="img-fluid rounded w-100"
                                style={{
                                    objectFit: 'cover',
                                    height: '100%',
                                    maxHeight: '100%', // Ensures it doesn't expand beyond container
                                }}
                            />
                        </div>
                    </Col>

                    <Col md={6} className="d-flex align-items-center wow fadeInUp" data-wow-delay="0.1s">
                        <Form onSubmit={handleSubmit} className="w-100 mt-3">
                            <Row className="g-3">
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Your Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            // onChange={handleChange}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only alphabets and spaces
                                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                                    handleChange(e); // Call your existing change handler
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            placeholder="Your Name"
                                            isInvalid={touchedFields.name && !formData.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Your Email"
                                            isInvalid={touchedFields.email && !formData.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Your Phone Number"
                                            maxLength={15}
                                            isInvalid={
                                                touchedFields.phone &&
                                                (formData.phone.trim() === '' || !/^[+\d\s]{1,15}$/.test(formData.phone))
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid phone number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>


                                <Col md={6}>
                                    <Form.Group controlId="reservation_date">
                                        <Form.Label>Reservation Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="reservation_date"
                                            value={formData.reservation_date}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touchedFields.reservation_date && (!formData.reservation_date || isMonday())}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {isMonday() ? "Reservations are not allowed on Mondays." : "Please select a reservation date."}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="time">
                                        <Form.Label>Reservation Time</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="time"
                                            value={formData.time}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touchedFields.time && !formData.time}
                                        >
                                            <option value="">Select a Time</option>
                                            {timeSlots.map((time, index) => (
                                                <option key={index} value={time}>{time}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a reservation time.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group controlId="number_of_people">
                                        <Form.Label>Party Size</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="number_of_people"
                                            value={formData.number_of_people}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touchedFields.number_of_people && !formData.number_of_people}
                                        >
                                            <option value="">Select Party Size</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9+</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a party size.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="special_requests">
                                        <Form.Label>Special Requests</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="special_requests"
                                            value={formData.special_requests}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button variant="primary" type="submit" className="w-100 py-3">
                                        {/* Book Table */}
                                        {isBooking ? 'Booking...' : 'Book Table'}
                                    </Button >
                                </Col >
                            </Row >
                        </Form >
                    </Col >                                         </Row >
            </Container >
        </Container >
    )
}

export default BookTable;

