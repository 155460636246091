import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { awsDownload } from '../Pages/api/linode-setup/download';

const SkeletonCard = () => {
  const skeletonStyles = {
    width: '100%',
    height: '200px',
    backgroundColor: '#e0e0e0',
    borderRadius: '8px',
  };

  return <div style={skeletonStyles} />;
};


const Modal = ({ imageUrl, onClose, loading, onNext, onPrev, totalImages }) => {
  const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const imgStyles = {
    maxWidth: '50%',
    maxHeight: '50%',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    display: loading ? 'none' : 'block',
  };

  const spinnerStyles = {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    display: loading ? 'block' : 'none',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '2%',
    right: '2%',
    width: '5vw',
    height: '5vw',
    maxWidth: '40px',
    maxHeight: '40px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    zIndex: 1001,
  };

  const navButtonStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '3vw', // Responsive font size
    color: 'white',
    background: 'rgba(0, 0, 0, 0.5)',
    border: 'none',
    borderRadius: '50%',
    width: '8vw',  // Responsive button size
    height: '8vw', // Responsive button size
    maxWidth: '50px',  // Fallback maximum size
    maxHeight: '50px',
    cursor: 'pointer',
    zIndex: 1002,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background 0.3s ease, transform 0.3s ease', // Smooth transition effect
  };

  const prevButtonStyles = {
    left: '2%',
  };

  const nextButtonStyles = {
    right: '2%',
  };

  return (
    <div style={modalStyles}>
      <button style={closeButtonStyles} onClick={onClose}>
        &times;
      </button>
      <div style={spinnerStyles} />
      <img src={imageUrl} alt="Large view" style={imgStyles} />
      {totalImages > 1 && (
        <>
          <button
            style={{ ...navButtonStyles, ...prevButtonStyles }}
            onClick={onPrev}
            onMouseOver={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.8)'} // Hover effect
            onMouseOut={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.5)'} // Revert hover effect
          >
            &lt;
          </button>
          <button
            style={{ ...navButtonStyles, ...nextButtonStyles }}
            onClick={onNext}
            onMouseOver={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.8)'} // Hover effect
            onMouseOut={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.5)'} // Revert hover effect
          >
            &gt;
          </button>
        </>
      )}
    </div>
  );
};

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/gallery`);
        const items = response.data?.data?.galleryItems;

        if (items) {
          const mediaData = await Promise.all(
            items.map(async (item) => {
              if (item.image_url && item.image_url !== 'null') {
                let linkSplit = item.image_url;
                const urlParts = linkSplit?.split('.com/');
                const imageUrl = await awsDownload(urlParts[1], 36000);
                item.download_url = imageUrl;
              }
              return item;
            })
          );

          setGalleryItems(mediaData);
        }
      } catch (err) {
        console.error('Error fetching Photos:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryItems();
  }, []);

  const handleImageLoad = (index) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const openImageModal = (url, index) => {
    setModalLoading(true);
    const img = new Image();
    img.src = url;

    img.onload = () => {
      setModalLoading(false);
      setSelectedImage(url);
      setCurrentIndex(index);
    };
  };

  const closeModal = () => {
    setSelectedImage(null);
    setCurrentIndex(null);
  };

  const goToNextImage = () => {
    const nextIndex = (currentIndex + 1) % galleryItems.length;
    setSelectedImage(galleryItems[nextIndex].download_url || galleryItems[nextIndex].image_url);
    setCurrentIndex(nextIndex);
  };

  const goToPreviousImage = () => {
    const prevIndex = (currentIndex - 1 + galleryItems.length) % galleryItems.length;
    setSelectedImage(galleryItems[prevIndex].download_url || galleryItems[prevIndex].image_url);
    setCurrentIndex(prevIndex);
  };

  const galleryStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 200px)',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
  };

  const responsiveStyles = `
  @media (max-width: 768px) {
    .gallery-grid {
      justify-content: center; 
      grid-template-columns: repeat(auto-fit, 100px);
    }
  }
   @media (max-width: 425px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

  const imageStyles = {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer'
  };

  return (
    <div className="container-xxl py-5">
      <style>{responsiveStyles}</style>
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">Gallery</h5>
          <h1 className="mb-5">Explore Our Culinary Creations</h1>
        </div>
        {loading ? (
          <div style={galleryStyles}>
            {Array(8)
              .fill(0)
              .map((_, index) => (
                <SkeletonCard key={index} />
              ))}
          </div>
        ) : galleryItems.length > 0 ? (
          <div style={galleryStyles}>
            {galleryItems.map((item, index) => (
              <div
                key={item.u_id}
                className="wow fadeInUp"
                data-wow-delay={`${0.1 + index * 0.1}s`}
              >
                {!imageLoading[index] && <SkeletonCard />}
                <img
                  src={item.download_url || item.image_url}
                  alt={item.alt || 'Gallery Image'}
                  style={{ ...imageStyles, display: imageLoading[index] ? 'block' : 'none' }}
                  onLoad={() => handleImageLoad(index)}
                  onClick={() => openImageModal(item.download_url || item.image_url, index)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            className="text-center w-100"
            style={{
              padding: '50px 20px',
              backgroundColor: '#f8f9fa',
              border: '2px dashed #dee2e6',
              borderRadius: '8px',
              marginTop: '20px',
            }}
          >
            <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
              No Images Available
            </h3>
            <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
              We currently have no images to display. Please check back later.
            </p>
          </div>
        )}
        {selectedImage && (
          <Modal
            imageUrl={selectedImage}
            onClose={closeModal}
            loading={modalLoading}
            onNext={goToNextImage}
            onPrev={goToPreviousImage}
            totalImages={galleryItems.length}
          />
        )}
      </div>
    </div>
  );
};

export default Gallery;
