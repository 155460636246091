import React, { useEffect } from 'react';
import hero_Img from '../assets/img/hero.png';
import '../assets/animate/animate.css';

const Hero = () => {
    useEffect(() => {
        // List of words in the h1
        const words = ['Enjoy', 'Our', 'Delicious', 'Meal'];
        const wordElements = words.map((word, index) => document.getElementById(`word${index + 1}`));

        // Define colors for the flicker effect
        const colors = ['#ff00ff', '#ffea00', '#00ff00', '#00ffff'];

        // Randomly flicker words for a total of 2 seconds
        const randomFlicker = () => {
            let flickerCount = 0;
            let flickerInterval = setInterval(() => {
                if (flickerCount < 10) {
                    // Randomly select a word and flicker it
                    const randomIndex = Math.floor(Math.random() * wordElements.length);
                    const wordElement = wordElements[randomIndex];

                    // Randomly select a color from the colors array
                    const randomColor = colors[Math.floor(Math.random() * colors.length)];

                    // Flicker effect (visible/invisible) with random colors for text-shadow
                    wordElement.style.opacity = 0;
                    wordElement.style.textShadow = `0 0 5px ${randomColor}, 0 0 10px ${randomColor}, 0 0 20px ${randomColor}`;

                    setTimeout(() => {
                        wordElement.style.opacity = 1;
                        wordElement.style.textShadow = `0 0 5px ${randomColor}, 0 0 10px ${randomColor}, 0 0 20px ${randomColor}, 0 0 30px ${randomColor}`;
                    }, 100);

                    flickerCount++;
                } else {
                    clearInterval(flickerInterval);
                    glowUp(); // Trigger the glow effect after flickering
                }
            }, 200); // Flicker every 200ms
        };

        // Apply the glow effect to all words
        const glowUp = () => {
            wordElements.forEach((wordElement) => {
                wordElement.style.textShadow = '0 0 5px #fff, 0 0 10px rgb(255, 3, 3), 0 0 15px rgb(255, 3, 3), 0 0 20px rgb(255, 3, 3), 0 0 30px rgb(255, 3, 3), 0 0 40px rgb(255, 3, 3), 0 0 50px rgb(255, 3, 3)';
                wordElement.style.opacity = 1;
            });
        };

        // Start the flickering when the component mounts
        randomFlicker();

        return () => {
            // Clean up intervals if the component unmounts
            clearInterval(randomFlicker);
        };
    }, []);

    return (
        <div className="container-fluid py-5 bg-dark hero-header mb-5">
            <div className="container my-5 py-5">
                <div className="row align-items-center g-5">
                    <div className="col-lg-6 text-center text-lg-start">
                        <h1
                            className="display-3 text-white neon-text"
                            style={{
                                fontSize: '3.4rem',
                                fontWeight: '200',
                            }}
                        >
                            <span className="word" id="word1">Enjoy </span>
                            <span className="word" id="word2">Our </span>
                            <br />
                            <span className="word" id="word3">Delicious </span>
                            <span className="word" id="word4">Meal !!! </span>
                        </h1>

                        <p className="text-white mb-4 pb-2">
                            Enjoy mouth-watering dishes made with fresh ingredients and authentic recipes. From rich curries to sizzling tandoori delights, every bite is a celebration of taste.
                            <br/>
                            <br/>
                            Book your table now and treat yourself !
                        </p>
                        <a className="btn btn-primary py-3 px-5 mt-2" href="/booking">
                            Book A Table
                        </a>
                    </div>
                    <div className="col-lg-6 text-center text-lg-end overflow-hidden">
                        <img className="img-fluid" src={hero_Img} alt="Delicious Meal" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
