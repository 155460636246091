/* eslint-disable no-console */
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { toFileName } from "../../../utils/to-file-name";

export const awsUpload = async (fileName, file) => {

  const s3Client = new S3Client({
    endpoint: process.env.REACT_APP_LINODE_ENDPOINT,
    region: process.env.REACT_APP_LINODE_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    },
  });

  const updatedFileName = !fileName.endsWith("/")
    ? fileName
    : fileName.slice(0, fileName.lastIndexOf("/"));
  const extension = file?.name?.slice(file?.name?.lastIndexOf("."));

  if (fileName && file) {
    try {
      const command = new PutObjectCommand({
        Bucket: process.env.REACT_APP_BUCKET_ID,
        Key: toFileName(`${updatedFileName}${extension}`),
        Body: file,
        ACL: "private",
        ContentType: file.type,
        ContentDisposition: "inline",
      });

      const results = await s3Client.send(command);

      if (results?.$metadata?.httpStatusCode === 200) {
        return {
          status: true,
          filePath: toFileName(`${updatedFileName}${extension}`),
        };
      } else {
       return null;
      }
    } catch (err) {
      console.info("error => ", err);

      return null;
    }
  } else {
    return null;
  }
};
