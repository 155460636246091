import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader';
import Footer from '../Components/Footer';
import CareerInfo from '../Components/CareerInfo';


const Career = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Career', url: '/career', active: true }
    ];
  return (
   <>
   <Navbar/>
   <HeroHeader title="Career" breadcrumbLinks={breadcrumbLinks} />
   <CareerInfo/>
   <Footer/>
   </>
  )
}

export default Career