import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import about1 from '../assets/img/image-4.png';
import about2 from '../assets/img/image-4.png';
import about3 from '../assets/img/image-3.png';
import about4 from '../assets/img/image.png';

const AboutComponent = () => {
    const [inView, setInView] = useState(false);
    const counterRef = useRef(null);

    // IntersectionObserver to trigger when the section is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true); // Start counting when in view
                }
            },
            { threshold: 1 } // Trigger when 50% of the element is visible
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 align-items-center mb-5">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6 text-start">
                                <img
                                    className="img-fluid rounded w-100 wow zoomIn"
                                    data-wow-delay="0.1s"
                                    src={about1}
                                    alt="About 1"
                                />
                            </div>
                            <div className="col-6 text-start">
                                <img
                                    className="img-fluid rounded w-75 wow zoomIn"
                                    data-wow-delay="0.3s"
                                    src={about2}
                                    alt="About 2"
                                    style={{ marginTop: '25%' }}
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img
                                    className="img-fluid rounded w-75 wow zoomIn"
                                    data-wow-delay="0.5s"
                                    src={about3}
                                    alt="About 3"
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img
                                    className="img-fluid rounded w-100 wow zoomIn"
                                    data-wow-delay="0.7s"
                                    src={about4}
                                    alt="About 4"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>
                        <h1 className="mb-4">
                            Welcome to <i className="fa fa-utensils text-primary me-2"></i>Foodie's Choice
                        </h1>
                        <p className="mb-4" style={{textAlign:"justify"}}>
                            Welcome to Foodie's Choice, where the vibrant flavors of India come alive in the heart of London! As a new venture, we are dedicated to bringing you an authentic Indian dining experience that combines traditional recipes with a contemporary touch.
                        </p>
                        <p className="mb-4" style={{textAlign:"justify"}}>
                        Whether you're craving a comforting meal or looking to explore the unique spices and flavors of Indian cuisine, Foodie's Choice is here to serve you an unforgettable journey of taste and tradition.
                        Join us as we share our love for Indian food !!!
                        </p>
                        <div className="row g-4 mb-4">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                    <h1 className="flex-shrink-0 display-5 text-primary mb-0" ref={counterRef}>
                                        {inView && <CountUp start={0} end={50} duration={5} />}+
                                    </h1>
                                    <div className="ps-4">
                                        <p className="mb-0">Types</p>
                                        <h6 className="text-uppercase mb-0">Food Items Available</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="btn btn-primary py-3 px-5 mt-2" href="/menu">
                            Explore More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutComponent;



