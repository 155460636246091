import React from 'react';

const HeroHeader = ({ title, breadcrumbLinks = [] }) => {
    return (
        <div className="container-fluid py-5 bg-dark hero-header mb-5">
            <div className="container text-center my-5 pt-5 pb-4">
                <h1 className="display-3 text-white mb-3 animated slideInDown"  style={{
                                color: '#fff',
                                fontWeight:'200',
                                textShadow: `
            0 0 5px #fff,
            0 0 10px #fff,
            0 0 20px rgb(255, 3, 3),
            0 0 30px rgb(255, 3, 3),
            0 0 40px rgb(255, 3, 3),
            0 0 50px rgb(255, 3, 3),
            0 0 75px rgb(255, 3, 3)`
                            }}>{title}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center text-uppercase">
                        {breadcrumbLinks.map((link, index) => (
                            <li key={index} className={`breadcrumb-item ${link.active ? 'text-white active' : ''}`} aria-current={link.active ? 'page' : ''} >
                                {link.active ? link.name : <a href={link.url}>{link.name}</a>}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default HeroHeader;

