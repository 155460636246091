import React from 'react';

const GoogleMap = () => {

    const googleMapsSrc = process.env.REACT_APP_GOOGLE_MAPS_URL;

    return (
        
        // <div className=" p-0">
        //     <div className="row">
        //         <iframe
        //             title={"Foodie's Choice Location"}
        //             src={googleMapsSrc}
        //             width={width}
        //             height={height}
        //             style={{ width: '100%' }}
        //             allowFullScreen=""
        //             loading="lazy"
        //             referrerPolicy="no-referrer-when-downgrade"
        //         ></iframe>
        //     </div>
        // </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="card map border-0">
                        <iframe title="Nadiad Heart Hospital Location"
                            src={googleMapsSrc}
                            width="100%"
                            height="600"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoogleMap;
