import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/logo.png";
import { BsCaretLeft, BsCaretRight } from "react-icons/bs";
import FallbackSpinner from "../Components/spinner/Spinner"

const Menu = () => {
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState("");
    const [screenItemsPerPage, setScreenItemsPerPage] = useState(4);
    const [loading, setLoading] = useState(true);

    // Responsive handler
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setScreenItemsPerPage(1);
        } else if (window.innerWidth < 1024) {
            setScreenItemsPerPage(3);
        } else {
            setScreenItemsPerPage(4);
        }
    };

    // Fetch categories from API
    const fetchCategories = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            setLoading(true);
            const response = await axios.get(`${apiUrl}/menu`);
            const categoryData = response.data?.data?.categories;

            if (Array.isArray(categoryData)) {
                setCategories(categoryData);
                setActiveCategory(categoryData[0]?.u_id); // Set default active category
            } else {
                throw new Error("Categories data is not in the expected format.");
            }
        } catch (err) {
            console.error("Error fetching categories:", err.message);
            setError("Failed to load categories. Please try again later.");
        } finally {
            setLoading(false); // Set loading to false after API call is finished
        }
    };

    useEffect(() => {
        fetchCategories();
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize); // Cleanup
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (categories.length > 0) {
            const totalPages = Math.ceil(categories.length / screenItemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        }
    }, [screenItemsPerPage, categories, currentPage]);

    const handleCategoryChange = (categoryId) => {
        setActiveCategory(categoryId);
    };

    // Pagination logic
    const totalPages = Math.ceil(categories.length / screenItemsPerPage);
    const isPrevDisabled = currentPage === 1;
    const isNextDisabled = currentPage === totalPages;

    const currentCategories = categories.slice(
        (currentPage - 1) * screenItemsPerPage,
        currentPage * screenItemsPerPage
    );

    const handlePagination = (direction) => {
        let newPage = currentPage;

        if (direction === "next" && currentPage < totalPages) {
            newPage += 1;
        } else if (direction === "prev" && currentPage > 1) {
            newPage -= 1;
        }

        setCurrentPage(newPage);

        // Set the active category to the first category of the new page
        const newCategories = categories.slice(
            (newPage - 1) * screenItemsPerPage,
            newPage * screenItemsPerPage
        );

        if (newCategories.length > 0) {
            setActiveCategory(newCategories[0].u_id);
        }
    };

    // Active Category's Food Items
    const activeCategoryData = categories.find((category) => category.u_id === activeCategory);
    const foodItems = activeCategoryData?.food_items || [];

    return (
        <div className="container py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="text-center">
                <h5 className="section-title ff-secondary text-center text-primary fw-normal">
                    Food Menu
                </h5>
                <h1 className="mb-3">Most Popular Items</h1>
            </div>

            {loading ? (
                <FallbackSpinner />
            ) : (
                <>
                    {/* Category Navigation */}
                    <div className="text-center d-flex justify-content-center align-items-center mb-3">
                        {/* Previous Button */}
                        <button
                            onClick={() => handlePagination("prev")}
                            className="mx-3"
                            style={{
                                fontSize: "30px",
                                padding: "10px 20px",
                                border: "none",
                                backgroundColor: "transparent",
                                color: isPrevDisabled ? "#CCCCCC" : "#000",
                                cursor: isPrevDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled={isPrevDisabled}
                        >
                            <BsCaretLeft style={{ color: "rgb(239, 136, 26)" }} />
                        </button>

                        {/* Category Items */}
                        {currentCategories.map((category) => {
                            const hasFoodItems = category.food_items && category.food_items.length > 0;

                            return (
                                <li
                                    className="nav-item d-flex justify-content-center align-items-center"
                                    key={category.u_id}
                                    style={{ listStyle: "none", marginTop: "1.5rem" }}
                                >
                                    <button
                                        className={`d-flex align-items-center text-start mx-3 ms-0 pb-3 ${activeCategory === category.u_id && hasFoodItems ? "active" : ""
                                            }`}
                                        onClick={() => handleCategoryChange(category.u_id)}
                                        disabled={!hasFoodItems}
                                        style={{
                                            cursor: hasFoodItems ? "pointer" : "not-allowed",
                                            opacity: hasFoodItems ? 1 : 0.5,
                                            border: "none",
                                            background: "inherit",
                                            borderBottom:
                                                activeCategory === category.u_id ? "2px solid #FEA116" : "none",
                                        }}
                                    >
                                        <i
                                            className={`fa ${category.icon} fa-2x`}
                                            style={{
                                                color: hasFoodItems ? "#FEA116" : "#CCCCCC",
                                                filter: hasFoodItems ? "none" : "grayscale(100%)",
                                            }}
                                        ></i>
                                        <div className="text-center">
                                            <h6
                                                className="mt-n1 mb-0"
                                                style={{
                                                    color: hasFoodItems ? "#0F172B" : "#777",
                                                }}
                                            >
                                                {category.name}
                                            </h6>
                                        </div>
                                    </button>
                                </li>
                            );
                        })}

                        {/* Next Button */}
                        <button
                            onClick={() => handlePagination("next")}
                            className="mx-3"
                            style={{
                                fontSize: "30px",
                                padding: "10px 20px",
                                border: "none",
                                backgroundColor: "transparent",
                                color: isNextDisabled ? "#CCCCCC" : "#000",
                                cursor: isNextDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled={isNextDisabled}
                        >
                            <BsCaretRight style={{ color: "#FEA116" }} />
                        </button>
                    </div>

                    {/* Food Items */}
                    <div className="row g-4 wow fadeInUp" data-wow-delay="0.1s">
                        {foodItems.length > 0 ? (
                            foodItems.map((item) => (
                                <div className="col-md-4 col-sm-6" key={item.u_id}>
                                    <div className="card shadow-sm h-100" style={{ borderRadius: "2rem" }}>
                                        <img
                                            src={item.imageUrl || logo}
                                            className="card-img-top"
                                            alt={item.name}
                                            style={{
                                                height: "150px",
                                                width: "150px",
                                                objectFit: "cover",
                                                padding: "1rem",
                                            }}
                                        />
                                        <div
                                            className="card-body bg-dark text-white p-3 d-flex flex-column"
                                            style={{ borderRadius: "0rem 2rem 0rem 2rem" }}
                                        >
                                            <h5 className="card-title" style={{ color: "#FFFFFF", fontSize: "20px" }}>
                                                {item.name}
                                            </h5>
                                            <p className="card-text small fst-italic">{item.description}</p>
                                            <div className="d-flex justify-content-between align-items-center mt-auto">
                                                <span className="fw-bold">£ {item.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // <div className="text-center">No food items available</div>
                            <div
                                className="text-center w-100"
                                style={{
                                    padding: '50px 20px',
                                    backgroundColor: '#f8f9fa',
                                    border: '2px dashed #dee2e6',
                                    borderRadius: '8px',
                                    marginTop: '20px',
                                }}
                            >
                                <h3 style={{ fontSize: '24px', color: '#FEA116', fontWeight: 'bold' }}>
                                    Oops! It Looks Like We're Out of Stock...
                                </h3>
                                <p style={{ fontSize: '16px', color: '#6c757d', marginTop: '1rem' }}>
                                    We're working hard to bring you new and exciting food items! Stay tuned for upcoming delicious options. Your next favorite meal might be just around the corner!
                                </p>
                            </div>
                        )}
                    </div>

                    {/* Error Message */}
                    {error && <div className="alert alert-danger mt-4">{error}</div>}
                </>
            )}
        </div>
    );
};

export default Menu;

