import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader'
import BookTable from '../Components/Booking';
import Footer from '../Components/Footer';

const BookingPage = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Reservation', url: '/booking', active: true }
    ];
  return (
    <>
    <Navbar/>
    <HeroHeader title="Reservation" breadcrumbLinks={breadcrumbLinks}  className="hero-header-reservation" />
    <BookTable/>
    <Footer/>
    </>
  )
}

export default BookingPage