import React from 'react';
import animationData from '../spinner/loader-spinner.json'; // Ensure this is the correct path
import Lottie from 'lottie-react';  // Directly import Lottie

const FallbackSpinner = ({ sx }) => {
  return (
    <div
      className='App'
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx, // Optional style override
      }}
    >
      <Lottie
        animationData={animationData}
        loop
        autoplay
        style={{ height: 160, width: 160 }}
      />
    </div>
  );
}

export default FallbackSpinner;
