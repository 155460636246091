import React from 'react'
import Navbar from '../Components/Nav'
import HeroHeader from '../Components/HeroHeader'
import Menu from '../Components/Menu';
import Footer from '../Components/Footer';

const MenuPage = () => {
    const breadcrumbLinks = [
        { name: 'Home', url: '/', active: false },
        { name: 'Menu', url: '/menu', active: true }
    ];
  return (
    <>
    <Navbar/>
    <HeroHeader title="Food Menu" breadcrumbLinks={breadcrumbLinks} />
    <Menu/>
    <Footer/>
    </>
  )
}

export default MenuPage