import React from 'react'
import Navbar from '../Components/Nav'
import Hero from '../Components/Hero'
import Service from '../Components/Service'
import About from '../Components/About'
import Reservation from '../Components/Reservation'
import Testimonial from '../Components/Testimonial'
import Footer from '../Components/Footer'
import FeaturedItems from '../Components/FeaturedItems'
import GoogleMap from '../Components/GoogleMap'
import ReviewSection from '../Components/ReviewSection'

const Home = () => {
  return (
    <>
    <Navbar/>
    <Hero/>
    <Service/>
    <About/>
    <FeaturedItems/>
    <Reservation/>
    <Testimonial/>
    <ReviewSection/>
    <GoogleMap/>
    <Footer/>
    </>
  )
}

export default Home